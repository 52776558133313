import React from 'react'
import * as S from './styles'
import * as PathNavigation from '../../PathNavigation'
import Button from '../../atoms/Button'
import { ReactComponent as Book } from '../../../images/kitsPassados/book.svg'
import { Category } from '@shared/interfaces/Category'
import { PageKits } from '@shared/interfaces/Pages'
import { colors } from '@shared/interfaces/Colors'
import { bookTextsCategory, previousKitsTexts } from '@shared/interfaces/BookTexts'
import { dispatchGlobalEvent } from '@shared/services/tracking'
import { SUBSCRIBE_BUTTON_INFO } from '@site/components/Menu/constants'

const BookButtons = ( { category, page, link }: BookButtons.Props ): React.ReactElement => {
  const handleStoreButtonClick = () => {
    dispatchGlobalEvent( 'Kits Passados', 'store_from_previouskits' )
  }

  return (
    <S.Container
      $showStoreButton={ Boolean( link || page !== 'bookKits' ) }
      className="book-cover"
      page={ page }
    >
      <Button
        backgroundColor={ colors[ category ].SOLID!.BUTTON_BACKGROUND }
        className="book-cover__button"
        color={ colors[ category ].SOLID!.BUTTON_COLOR }
        hover={ colors[ category ].SOLID!.BUTTON_HOVER }
        href={ SUBSCRIBE_BUTTON_INFO.path[ 'absoluteClubSubscriptionAnchor' ][ category ] }
      >
        <Book className="book-cover__icon" />
        { bookTextsCategory[ category ]!.SUBSCRIBE_TEXT }
      </Button>
      { page !== 'bookKits' ? (
        <Button
          backgroundColor={ colors[ category ].OUTLINE!.BUTTON_BACKGROUND }
          border={ colors[ category ].OUTLINE!.BUTTON_COLOR }
          className="book-cover__button"
          color={ colors[ category ].OUTLINE!.BUTTON_COLOR }
          hover={ colors[ category ].OUTLINE!.BUTTON_HOVER }
          href={ PathNavigation[ category ] }
        >
          { bookTextsCategory[ category ]!.KNOW_TEXT }
        </Button> )
        : ( link &&
          <Button
            backgroundColor={ colors[ category ].OUTLINE!.BUTTON_BACKGROUND }
            border={ colors[ category ].OUTLINE!.BUTTON_COLOR }
            className="book-cover__button"
            color={ colors[ category ].OUTLINE!.BUTTON_COLOR }
            hover={ colors[ category ].OUTLINE!.BUTTON_HOVER }
            href={ link }
            target="_blank"
            onClick={ handleStoreButtonClick }
          >
            { previousKitsTexts!.STORE }
          </Button>
        ) }
    </S.Container>
  )
}

namespace BookButtons {
  export interface Props {
    category: Category.Type
    page: PageKits
    link?: string | undefined
    target?: '_blank' | '_self'
  }
}

export default BookButtons
