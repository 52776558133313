import styled from 'styled-components'

const Container = styled.div`
  background: ${props => props.theme.cores.WHITE};
  padding: 16px;
  border-radius: 16px;
  text-align: center;

  > a {
    margin-top: 16px;
    height: 48px;
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    padding: 24px 32px;
  }
`

const PricingContent = styled.div`
  width: min-content;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > p:last-child { 
    margin-left: auto;
  }
`

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-right: 4px;
  }
`

export { Container, PricingContent, Price }
