import styled from 'styled-components'

const Container = styled.div`
  .book-list-header__round-helper {
    transform: rotate(180deg);
    position: absolute;
  }
  position: relative;
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    max-width: 494px;
  }
`

const BookListWrapper = styled.div`
  top: 0;
  width: 100%;
  margin-top: 32px;
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    position: initial;
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  margin-top: -32px;
`

const Background = styled.img`
  width: 100%;
  background-color: ${props => props.theme.cores[ 'GREY-100-BORDER-BG' ]};
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: none;
  }
`
export { Background, Container, BookListWrapper, BackgroundWrapper }
