import styled from 'styled-components'

const ListWrapper = styled.div`
  > div:first-child {
    margin-bottom: 64px;
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: flex;
    justify-content: center;
    > div:first-child {
      margin-bottom: 0;
    }
  }
`

export { ListWrapper }
