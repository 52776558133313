import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 76px;
  padding-top: 12px;

  background: radial-gradient( 100% 100% at 50% 100%, #FFE1D1 0%, #FFF5E6 100%);

  > svg {
    position: absolute;
    bottom: -2px;
    left: 0;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      height: 19px;
    }
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    height: 80px;
  }
`

const Books = styled.div`
  width: 226px;
  height: 100%;
  margin: auto;

  display: flex;
  align-items: end;
  justify-content: space-between;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    width: 638px;
  }
`

const Wall = styled.span`
  width: 3px;
  height: 58px;
  
  background: #343A4F;
  box-shadow: 0px 1px 4px rgba(19, 26, 45, 0.2);
  border-radius: 1px 1px 0px 0px;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    height: 62px;
  }
`

export { Container, Books, Wall }
