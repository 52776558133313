/* eslint-disable react/jsx-closing-bracket-location */
import { Category } from '@shared/interfaces/Category'
import TagRibbon from '@site/components/atoms/TagRibbon'
import React from 'react'
import * as S from './styles'

const BookSpine = ( { position, category, coverColor, flagColor }: BookSpine.Props ): React.ReactElement => {
  return (
    <S.Container
      $category={ category }
      $color={ coverColor }
      $position={ position }
      className="book-spine"
      data-testid="book-spine"
    >
      <TagRibbon
        color={ flagColor }
        height={ 18 }
        width={ 10 } />
    </S.Container>
  )
}

namespace BookSpine {
  export interface Props {
    position: number
    category: Category.Type
    coverColor: string
    flagColor: string
  }
}

export default BookSpine
