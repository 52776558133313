import React from 'react'
import BooksPreviousKits from '../../components/organisms/BooksPreviousKits'
import { graphql } from 'gatsby'
import { Book } from 'packages/shared/interfaces/Book'
import PreviousKitsHeader from '@site/components/organisms/PreviousKitsHeader'
import BaseStructure from '@site/components/organisms/BaseStructure'
import * as S from './_styles'
import CommonHead from '@shared/meta/CommonHead'
import SubscriptionOffer from '@site/components/organisms/SubscriptionOffer'

export function Head() {
  return (
    <CommonHead
      title="Conheça nossos kits passados | TAG Livros"
      description="Quer saber o que já foi enviado para os participantes do nosso clube de assinatura? Confira esses títulos incríveis com edições de tirar o fôlego!"
    />
  )
}

const KitsPassados = ( { data }: KitsPassados.Props ): React.ReactNode => {
  const { allStrapiKitsPassados, allStrapiHomes } = data
  const previousKitsData = allStrapiKitsPassados.edges[ 0 ].node
  const booksCuradoria = previousKitsData.curadoriaBooks
  const booksIneditos = previousKitsData.ineditosBooks
  const { clubs } = allStrapiHomes.edges[ 0 ].node

  return (
    <BaseStructure clubs={ clubs }>
      <PreviousKitsHeader
        booksCuradoria={ booksCuradoria }
        booksIneditos={ booksIneditos }
        description={ previousKitsData.title }
      />
      <S.ListWrapper>
        <BooksPreviousKits
          books={ booksIneditos }
          description={ previousKitsData.ineditosDescription }
          category="ineditos"
        />
        <BooksPreviousKits
          books={ booksCuradoria }
          description={ previousKitsData.curadoriaDescription }
          category="curadoria"
        />
      </S.ListWrapper>
    </BaseStructure>
  )
}

namespace KitsPassados {
  export interface Node {
    title: string
    curadoriaDescription: string
    ineditosDescription: string
    curadoriaBooks: Array<{ book: Book }>
    ineditosBooks: Array<{ book: Book }>
  }
  export interface Props {
    data: {
      allStrapiKitsPassados: {
        edges: [
          {
            node: Node
          }
        ]
      }
      allStrapiHomes: {
        edges: [
          {
            node: {
              clubs: SubscriptionOffer.Props
            }
          }
        ]
      }
    }
  }
}

export default KitsPassados

export const query = graphql`{
  allStrapiKitsPassados(limit: 1, sort: {order: DESC, fields: strapiId}) {
    edges {
      node {
        title
        curadoriaDescription
        ineditosDescription
        curadoriaBooks {
          book {
            id
            book {
              id
              title
              authorship
              additionalInformation
              tagMonth
              appRating
              urlStore
            }
            bookImage {
              starHexColor
              flagHexColor
              id
              cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              background {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            bookGallery {
              caption
              id
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            aboutBook {
              aboutAuthorship
              authorshipPronoun
              customTextParagraph
              customTitleParagraph
              id
              synopsis
            }
            comments {
              rating
              comment
              name
              id
            }
          }
        }
        ineditosBooks {
          book {
            id
            book {
              id
              title
              authorship
              additionalInformation
              tagMonth
              appRating
              urlStore
            }
            bookImage {
              starHexColor
              flagHexColor
              id
              cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              background {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            bookGallery {
              caption
              id
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      width: 1500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            aboutBook {
              aboutAuthorship
              authorshipPronoun
              customTextParagraph
              customTitleParagraph
              id
              synopsis
            }
            comments {
              rating
              comment
              name
              id
            }
          }
        }
      }
    }
  }
  allStrapiHomes(limit: 1, sort: {order: DESC, fields: strapiId}) {
    edges {
      node {
        clubs {
          subtitle
          title
          image {
            mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            desktop {
              localFile {
                childImageSharp {
                  gatsbyImageData (
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
          description
          price
          cta {
            text
            link
          }
          footer
        }
      }
    }
  }
}
`
