import { PageKits } from '@shared/interfaces/Pages'
import { tagTheme } from '@taglivros/tag-components'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<Wrapper.Props>`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  overflow: auto;
  .book-list__view-more-button {
    z-index: 1;
    width: 150px;
    min-width: 134px;
    height: 210px;
    margin: 0 auto;
    svg {
      margin-left: 8px;
      path {
        stroke: ${props => props.theme.cores[ props.color ]};
      }
    }
  }
  .book-list__book-button {
    margin: ${props => props.page === 'categoryKits' ? '0 8px 24px' : '0 16px 16px 0'};
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 494px;
    overflow: initial;
    .book-list__view-more-button {
      height: 40px;
      border: none;
    }
    .book-list__book-button {
      margin: 0 0 24px 0;
      > span {
        display: none;
      }
      :hover {
        .book-cover {
          span {
            opacity: 1;
            transition: .8s ease;
            z-index: 1;
          }
          > div, img {
            transition: .6s ease;
            opacity: 30%
          }
        }
      }
    }
  }
  ${props => props.page === 'categoryKits' && css`
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px;
    > div {
      margin-right: 0;
    }
    @media(min-width: ${props.theme.mediaQuery.smallpc}) {
      margin: auto;
      width: 994px;
    }
  `}
`
namespace Wrapper {
  export interface Props {
    color: keyof typeof tagTheme.cores
    page: PageKits
  }
}
export { Wrapper }
