import React from 'react'
import * as S from './styles'
import { ReactComponent as Star } from '../../../images/kitsPassados/star-rating.svg'
import { Book } from '@shared/interfaces/Book'
import { Pill, Typography } from '@taglivros/tag-components'
import { Category } from '@shared/interfaces/Category'
import { previousKitsTexts } from '@shared/interfaces/BookTexts'
import { PreviousKitAnimationContext } from '@site/contexts/PreviousKitAnimationContext'
import { getImage } from 'gatsby-plugin-image'

const BookCover = ( { book, category }: BookCover.Props ): React.ReactElement => {
  const { appRating } = book.book
  const { setSelectedBookCoverPosition, setShouldAnimate } = React.useContext( PreviousKitAnimationContext )
  const coverImage = getImage( book.bookImage.cover.localFile )!

  return (
    <S.Container
      className="book-cover"
      onClick={ ( event ) => {
        const {
          'top': initialTopDistance,
          'left': initialLeftDistance,
          'width': initialCoverWidth
        } = event.currentTarget.getBoundingClientRect()

        const isSmallScreen = window.screen.width < 1025
        const finalTopDistance = isSmallScreen ? 72 : 0
        const finalLeftDistance = isSmallScreen ? window.screen.width / 2 - initialCoverWidth / 2 : 0

        setSelectedBookCoverPosition( {
          'top': initialTopDistance - finalTopDistance,
          'left': initialLeftDistance - finalLeftDistance,
          'width': initialCoverWidth,
        } )

        setShouldAnimate( true )
      } }
    >
      <Typography
        as="span"
        color={ category === 'curadoria' ? 'TAG1-700' : 'TAG2-650-TXT' }
        variant="text2"
        fontFamily="notoSans"
        fontWeight="xl"
      >
        { previousKitsTexts.DETAILS }
      </Typography>
      <S.Cover
        alt={ `capa: ${book.book.title}` }
        className="book-cover__cover"
        image={ coverImage }
      />
      <Pill
        Icon={ Star }
        content={
          <Typography
            as="p"
            color="GREY-700-TXT"
            fontWeight="s"
            fontFamily="notoSans"
            variant="text3"
          >
            { appRating ? appRating.toFixed( 1 ) : '—' }
          </Typography>
        }
      />
    </S.Container>
  )
}

namespace BookCover {
  export interface Props {
    book: Book
    category: Category.Type
  }
}

export default BookCover
