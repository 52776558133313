import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #091539;
  padding: 32px 16px;
  text-align: center;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    padding: 32px 120px;
    text-align: initial;
  }
`

const Content = styled.div`
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    width: 350px;
  }
`

const Description = styled.div`
  margin: 0 16px;

  > div:last-child {
    margin-top: 8px;
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    margin: 0 0 24px;
  }
`

const ImageKit = styled( GatsbyImage )`
  margin: 16px 0;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    width: auto;
    height: min-content;
  }
`

const SmallText = styled.div`
  margin: 16px 16px 0;
  text-align: center;

  p a {
    color: ${props => props.theme.cores.WHITE};
    font-family: inherit;
    text-decoration: underline;
  }

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    margin: 16px 0 0%;
  }
`

export { Container, Content, Description, ImageKit, SmallText }
