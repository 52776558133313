import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const Container = styled.div`
  position: relative;
  > div {
    position: relative;
    bottom: 41px;
    left: 5px;
    margin-bottom: -26px;
    background-color: rgba(255, 255, 255, 0.9);
    font-family: 'Noto Sans', sans-serif;
  }
  > span {
    padding: 12px 32px;
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: fit-content;
    opacity: 0;
    transform: translate(-50%, -100%);
  }
`

const Cover = styled( GatsbyImage )`
  max-width: 135px;
  max-height: 210px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgb(19 26 45 / 20%);
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    max-width: 215px;
    max-height: 335px;
  }
  /* Native override */
  bottom: 0 !important;
  left: 0 !important;
  margin-bottom: 5px !important;
`

export { Container, Cover }
