import styled from 'styled-components'

const Container = styled.div`
  margin-top: 48px;

  @media (min-width: 800px) {
    margin-top: 64px;
  }
`

const TextContainer = styled.div`
  width: 100%;
  padding: 24px 32px;
  text-align: center;

  & > h1 {
    letter-spacing: -0.01em;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      line-height: 48px;
    }
  }

  & > p {
    margin: 0 auto;
    line-height: 20px;

    @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
      max-width: 400px;
      line-height: 24px;
    }
  }
`

export { Container, TextContainer }
