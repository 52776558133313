import styled from 'styled-components'

const Container = styled.div`
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    height: 450px;
    max-height: 500px;
  }
`

const Details = styled.div`
  position: relative;
  width: 135px;
  > h2, p {
    margin-bottom: 2px;
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    width: 215px;
  }
`

export { Container, Details }
