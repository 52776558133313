import { Category } from '@shared/interfaces/Category'
import styled from 'styled-components'

const Container = styled.span<Container.Props>`
  display: ${props => props.$position < 6 ? 'block' : 'none'};
  width: 14px;
  height: ${props => props.$category === 'curadoria' ? '60px' : '56px'};

  background-color: ${props => props.$color};
  box-shadow: 0px 1px 4px rgba(19, 26, 45, 0.2);
  border-radius: 1px 1px 0px 0px;
  text-align: center;

  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: block;
    width: 18px;
    height: ${props => props.$category === 'curadoria' ? '64px' : '60px'};
  }
`

namespace Container {
  export interface Props {
    $position: number
    $category: Category.Type
    $color: string
  }
}

export { Container }
