import React, { useEffect, useState } from 'react'
import Footer from '@site/components/Footer/Footer'
import Menu from '@site/components/Menu/Menu'
import Newsletter from '@site/components/Newsletter/Newsletter'
import CookiesFab from '../CookiesFab'
import SubscriptionOffer from '../SubscriptionOffer'

function BaseStructure( { children, clubs }: BaseStructure.Props ) {
  const [ isPageRendered, setIsPageRendered ] = useState( false )

  useEffect( () => {
    setIsPageRendered( true )
  }, [] )

  return (
    <>
      <Menu />
      { children }
      { isPageRendered && <SubscriptionOffer { ...clubs } /> }
      <Newsletter />
      <Footer />
      <CookiesFab />
    </>
  )
}
namespace BaseStructure {
  export interface Props {
    children: React.ReactNode
    clubs: SubscriptionOffer.Props
  }
}

export default BaseStructure
