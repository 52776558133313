import { PageKits } from '@shared/interfaces/Pages'
import styled, { css } from 'styled-components'

const Container = styled.div<Container.Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => props.$showStoreButton ? 'height: 88px;' : 'max-height: 88px;'}
  margin: 32px 0;
  padding: ${props => props.page === 'bookKits' ? '0' : '0 24px'};
  .book-cover__icon {
    display: none;
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    .book-cover__icon {
      display: block;
      margin: -1px 12px -1px;
    }
  }
  ${props => props.page === 'categoryKits' && css`
    .book-cover__icon {
      display: none;
    }
    @media(min-width: ${props.theme.mediaQuery.smallpc}) {
      display: flex;
      flex-direction: row;
      justify-content: right;
      width: 994px;
      height: auto;
      margin: 32px auto;
      .book-cover__button {
        padding: 8px 32px;
        width: auto;
        &:first-child {
          margin-left: 20px;
          order: 2;
        }
      }
    }
  `}
  ${props => props.page === 'bookKits' && css`
    .book-cover__icon {
      display: none;
    }
    @media(min-width: ${props.theme.mediaQuery.smallpc}) {
      display: flex;
      flex-direction: row;
      justify-content: start;
      padding: 0;
      height: auto;
      margin: 24px auto;
      .book-cover__button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  `}
`
namespace Container {
  export interface Props {
    page: PageKits
    $showStoreButton: boolean
  }
}

export { Container }
