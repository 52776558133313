import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  .book-list-header__round-helper {
    transform: rotate(180deg);
    position: absolute;
  }
`

const Background = styled.img`
  width: 100%;
  margin-bottom: -215px;
  background-color: ${props => props.theme.cores[ 'GREY-100-BORDER-BG' ]};
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px 16px;
  > p { 
    display: none;
  }
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    justify-content: initial;
    flex-direction: column;
    align-items: center;
    text-align: center;
    > p {
      display: block;
      margin: 8px 0;
    }
    .book-list-header__grid {
      display: none;
    }
  }
`

const Logo = styled.img`
  max-width: 135px;
  max-height: 210px; 
  @media(min-width: ${props => props.theme.mediaQuery.smallpc}) {
    max-width: 215px;
    max-height: 335px;
  }
`

export { Container, Background, Wrapper, Logo }
