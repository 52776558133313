import { Category } from './Category'

interface ButtonTextsCategory {
  SUBSCRIBE_TEXT: string
  KNOW_TEXT: string
  CATEGORY: string
}

interface Texts {
  VIEW_MORE: string
  STORE: string
  RETURN: string
  DETAILS: string
  PREVIOUS_KITS: string
  CLOSE_BUTTON: string
  RATING_TEXT: string
  GO_TO_TOP_TEXT: string
  GALLERY_CLICK_ON_PHOTO: string
  BOOKRATINGS_FOOTER: string
  BOOKRATINGS_TITLE: string
  BOOKFILTER_TEXT: string
}

export const bookTextsCategory: Record<Category.Type, ButtonTextsCategory> = {
  'curadoria': {
    'CATEGORY': 'Curadoria',
    'SUBSCRIBE_TEXT': 'Assinar TAG Curadoria',
    'KNOW_TEXT': 'Conhecer TAG Curadoria',
  },
  'ineditos': {
    'CATEGORY': 'Inéditos',
    'SUBSCRIBE_TEXT': 'Assinar TAG Inéditos',
    'KNOW_TEXT': 'Conhecer TAG Inéditos',
  }
}

export const month: string[] = [ 'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez' ]

export const previousKitsTexts: Texts = {
  'RETURN': 'Voltar',
  'PREVIOUS_KITS': 'Kits Passados',
  'VIEW_MORE': 'Ver mais',
  'STORE': 'Comprar na Livraria Dois Pontos',
  'DETAILS': 'Ver detalhes',
  'CLOSE_BUTTON': 'Entendi',
  'RATING_TEXT': 'Esta é a nota média do livro no app exclusiva da TAG, onde as pessoas associadas podem conversar sobre o livro, ver os conteúdos extras e avaliar o que acharam da experiência do mês.',
  'GO_TO_TOP_TEXT': 'Voltar para o topo',
  'GALLERY_CLICK_ON_PHOTO': 'Clique nas imagens para ampliar',
  'BOOKRATINGS_TITLE': 'Comentários',
  'BOOKRATINGS_FOOTER': 'Comentários de pessoas associadas no app exclusivo da TAG.',
  'BOOKFILTER_TEXT': 'Selecione para filtrar por ano:'
}


