import { Typography } from '@taglivros/tag-components'
import { Book } from 'packages/shared/interfaces/Book'
import { previousKitsTexts } from '@shared/interfaces/BookTexts'
import React from 'react'
import Shelf from '../Shelf'
import * as S from './styles'

const PreviousKitsHeader = ( { booksCuradoria, booksIneditos, description }: PreviousKitsHeader.Props ): React.ReactElement => {
  return (
    <S.Container>
      <Shelf
        booksCuradoria={ booksCuradoria }
        booksIneditos={ booksIneditos }
      />

      <S.TextContainer>
        <Typography
          as="h1"
          color="TAG1-800"
          fontSizeDesktop="xxxxl"
          fontWeight="l"
          variant="customText"
        >
          { previousKitsTexts.PREVIOUS_KITS }
        </Typography>

        <Typography
          as="p"
          color="GREY-700-TXT"
          fontFamily="notoSans"
          fontSizeDesktop="s"
          fontSizeMobile="xs"
          fontWeight="s"
          variant="customText"
        >
          { description }
        </Typography>
      </S.TextContainer>
    </S.Container>
  )
}

namespace PreviousKitsHeader {
  export interface Props {
    description: string
    booksCuradoria: Array<{ book: Book }>
    booksIneditos: Array<{ book: Book }>
  }
}

export default PreviousKitsHeader
