import React from 'react'
import { Book } from '../../../../../shared/interfaces/Book'
import BookListHeader from '../../molecules/BookListHeader'
import BookList from '../BookList'
import { ReactComponent as RoundHelperSVG } from '@site/assets/helpers/rounded-top.svg'
import pattern from '../../../images/kitsPassados/pattern-caixinhas.png'

import * as S from './styles'
import { Category } from '@shared/interfaces/Category'

const BooksPreviousKits = ( { books, description, category }: BooksPreviousKits.Props ): React.ReactElement => {
  return (
    <S.Container className={ `books-previous-kits--${category}` }>
      <S.BackgroundWrapper>
        <RoundHelperSVG
          height="14"
          width="100%"
          className="book-list-header__round-helper"
        />
        <S.Background
          className="book-list-header__background"
          src={ pattern }
        />
      </S.BackgroundWrapper>
      <S.BookListWrapper>
        <BookListHeader
          category={ category }
          description={ description }
        />
        <BookList
          books={ books }
          category={ category }
          page="previousKits"
        />
      </S.BookListWrapper>
    </S.Container>
  )
}

namespace BooksPreviousKits {
  export interface Props {
    books: Array<{ book: Book }>
    description: string
    category: Category.Type
  }
}

export default BooksPreviousKits
