import React from 'react'
import * as S from './styles'
import { Book } from '../../../../../shared/interfaces/Book'
import BookCover from '../../molecules/BookCover'
import { Typography } from '@taglivros/tag-components'
import { formatMonthAndYearKit } from '@shared/utils/formatter'
import { Category } from '@shared/interfaces/Category'

const BookPreview = ( { book, category }: BookPreview.Props ): React.ReactElement => {
  return (
    <S.Container className="book-preview">
      <BookCover
        category={ category }
        book={ book }
      />
      <S.Details className="book-preview__details">
        <Typography
          as="p"
          color="GREY-500-TXT"
          fontFamily="notoSans"
          fontWeight="s"
          variant="helperText"
        >
          { formatMonthAndYearKit( book.book.tagMonth ) }
        </Typography>
        <Typography
          as="h2"
          color="GREY-700-TXT"
          fontFamily="notoSans"
          fontSizeDesktop="m"
          fontSizeMobile="s"
          variant="customText"
        >
          { book.book.title }
        </Typography>
        <Typography
          as="p"
          color="GREY-700-TXT"
          fontFamily="notoSans"
          fontWeight="s"
          variant="text3"
        >
          { book.book.authorship }
        </Typography>
        <Typography
          as="p"
          color="GREY-500-TXT"
          fontFamily="notoSans"
          fontWeight="s"
          variant="helperText"
        >
          { book.book.additionalInformation }
        </Typography>
      </S.Details>
    </S.Container>
  )
}

namespace BookPreview {
  export interface Props {
    book: Book
    category: Category.Type
  }
}

export default BookPreview
