import React from 'react'
import * as S from './styles'
import SubscriptionOfferPrice from '../../molecules/SubscriptionOfferPrice'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { ImageDataLike, getImage } from 'gatsby-plugin-image'
import Markdown from 'react-showdown'

const SubscriptionOffer = ( props: SubscriptionOffer.Props ): React.ReactElement => {
  const { subtitle, title, image, description, cta, footer } = props

  const mobileImage = getImage( image.mobile.localFile )!
  const desktopImage = getImage( image.desktop.localFile )!

  return (
    <S.Container
      className="subscription-offer__container"
    >
      <S.ImageKit
        className="subscription-offer__image-kit desktop-only"
        image={ desktopImage }
        alt={ title }
      />
      <S.Content className="subscription-offer__content">
        <S.Description className="subscription-offer__description">
          <Typography
            variant="subtitle-large-light"
            color="TAG2-200"
            as="p"
          >
            { subtitle }
          </Typography>
          <Typography
            variant="title-large-bold"
            color="TAG2-200"
            as="h3"
          >
            { title }
          </Typography>
          <Typography
            variant="text-medium-regular"
            color="WHITE"
            as="div"
          >
            <Markdown markdown={ description } />
          </Typography>
        </S.Description>
        <S.ImageKit
          className="subscription-offer__image-kit mobile-only"
          image={ mobileImage }
          alt={ title }
        />
        <SubscriptionOfferPrice
          cta={ cta }
        />
        <S.SmallText className="subscription-offer__small-text">
          <Typography
            variant="text-small-regular"
            color="WHITE"
            as="div"
          >
            <Markdown markdown={ footer } />
          </Typography>
        </S.SmallText>
      </S.Content>
    </S.Container>

  )
}
namespace SubscriptionOffer {
  export interface Props {
    subtitle: string
    title: string
    image: {
      mobile: Image
      desktop: Image
    }
    description: string
    cta: CTA
    footer: string
  }

  export interface Image {
    localFile: ImageDataLike
  }

  export interface CTA {
    text: string
    link: string
  }
}

export default SubscriptionOffer
