import React, { useState } from 'react'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import Button from '../../atoms/Button'
import constants from '../../../assets/constants/subscriptionOffer/texts'
import { formatPrice } from '@checkout/utils/formatter'
import useSubscriptionPlans from '@site/hooks/useSubscriptionPlans'
import { SubscribeModal } from '@site/components/SubscribeModal/SubscribeModal'

const SubscriptionOfferPrice = ( props: SubscriptionOfferPrice.Props ): React.ReactElement => {
  const [ displaySubscribeModal, setDisplaySubscribeModal ] = useState( false )
  const { cta } = props
  const cheapestMonthlyPlanPrice = () => {
    const planPricesPerKit = useSubscriptionPlans().map( ( plan: { pricePerKit: any } ) => plan.pricePerKit )
    return Math.min( ...planPricesPerKit )
  }

  return (
    <S.Container
      className="subscription-offer-price__container"
    >
      <S.PricingContent>
        <Typography
          variant="subtitle-medium-light"
          color="TAG1-800"
          as="p"
        >
          { constants.BEFORE_PRICE_TEXT }
        </Typography>
        <S.Price className="subscription-offer-price__price">
          <Typography
            variant="title-large-bold"
            color="TAG3-400"
          >
            { formatPrice( cheapestMonthlyPlanPrice() ) }
          </Typography>
          <Typography
            variant="subtitle-medium-light"
            color="TAG1-800"
          >
            { constants.PER_MONTH }
          </Typography>
        </S.Price>
        <Typography
          variant="text-small-regular"
          color="TAG1-800"
          as="p"
        >
          { constants.SHIPPING }
        </Typography>
      </S.PricingContent>
      <Button
        backgroundColor="CTA-300"
        className="subscription-offer-price__button"
        color="WHITE"
        hover="CTA-400"
        onClick={ () => setDisplaySubscribeModal( true ) }
      >
        { cta.text }
      </Button>
      <SubscribeModal
        shouldDisplay={ displaySubscribeModal }
        setShouldDisplay={ setDisplaySubscribeModal }
      />
    </S.Container>

  )
}
namespace SubscriptionOfferPrice {
  export interface Props {
    cta: CTA
  }

  export interface CTA {
    text: string
    link: string
  }
}

export default SubscriptionOfferPrice
