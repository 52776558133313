import { alternativeCheckout } from '@site/components/PathNavigation'

interface Texts {
  INTRODUCTION: string
  TITLE: string
  PARAGRAPH_FIRST_PART: string
  PARAGRAPH_SECOND_PART: string
  BEFORE_PRICE_TEXT: string
  PER_MONTH: string
  SHIPPING: string
  BUTTON: string
  BUTTON_DESTINATION: string
  ALT_IMAGE: string
  SMALL_TEXT_BOLD: string
  SMALL_TEXT_REGULAR: string
  SMALL_TEXT_LINK_TEXT: string
}

const texts: Texts = {
  'INTRODUCTION': 'Coloque os livros na sua vida',
  'TITLE': 'Assine a TAG!',
  'PARAGRAPH_FIRST_PART': 'Leia mais com a TAG. ',
  'PARAGRAPH_SECOND_PART': 'Receba todo mês na sua casa livros incríveis em edições exclusivas.',
  'BEFORE_PRICE_TEXT': 'a partir de',
  'PER_MONTH': '/mês',
  'SHIPPING': '+ taxa de entrega',
  'BUTTON': 'ASSINAR A TAG',
  'BUTTON_DESTINATION': alternativeCheckout,
  'ALT_IMAGE': 'Imagem do kit',
  'SMALL_TEXT_BOLD': 'Quer ver mais detalhes do que vem na caixinha?',
  'SMALL_TEXT_REGULAR': 'Veja nossa ',
  'SMALL_TEXT_LINK_TEXT': 'biblioteca de kits passados.',
}

export default texts
