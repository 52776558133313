/* eslint-disable function-paren-newline */
/* eslint-disable no-plusplus */
/* eslint-disable id-length */
import BookSpine from '@site/components/molecules/BookSpine'
import React from 'react'
import * as S from './styles'
import { Book } from 'packages/shared/interfaces/Book'
import { ReactComponent as RoundHelperSVG } from '@site/assets/helpers/rounded-top.svg'

const Shelf = ( { booksCuradoria, booksIneditos }: Shelf.Props ): React.ReactElement => {
  const latestBooks = ( () => {
    const books = []

    for ( let i = 0; i < 12; i++ ) {
      const currentCuradoriaBook = booksCuradoria[ i ]?.book
      const currentIneditosBook = booksIneditos[ i ]?.book

      books.push(
        <BookSpine
          category="curadoria"
          coverColor={ currentCuradoriaBook!.bookImage.starHexColor }
          flagColor={ currentCuradoriaBook!.bookImage.flagHexColor }
          position={ i }
        />
      )

      books.push(
        <BookSpine
          category="ineditos"
          coverColor={ currentIneditosBook!.bookImage.starHexColor }
          flagColor={ currentIneditosBook!.bookImage.flagHexColor }
          position={ i }
        />
      )
    }

    return books
  } )()

  return (
    <S.Container className="shelf">
      <S.Books>
        <S.Wall className="shelf__wall" />
        { latestBooks }
        <S.Wall className="shelf__wall" />
      </S.Books>

      <RoundHelperSVG
        height="14"
        width="100%"
      />
    </S.Container>
  )
}

namespace Shelf {
  export interface Props {
    booksCuradoria: Array<{ book: Book }>
    booksIneditos: Array<{ book: Book }>
  }
}

export default Shelf
