import React from 'react'
import * as S from './styles'
import * as PathNavigation from '../../PathNavigation'
import BookPreview from '../BookPreview'
import BookButtons from '../../molecules/BookButtons'
import Button from '../../atoms/Button'
import { ReactComponent as Arrow } from '../../../images/kitsPassados/arrow-right.svg'
import { Book } from '@shared/interfaces/Book'
import { Category } from '@shared/interfaces/Category'
import { PageKits } from '@shared/interfaces/Pages'
import { previousKitsTexts } from '@shared/interfaces/BookTexts'
import { colors } from '@shared/interfaces/Colors'
import { Link } from 'gatsby'
import { BookFilterContext } from '@site/contexts/BookFilterContext'
import { BookFilter } from '@shared/interfaces/BookFilter'

const BookList = ( { category, books, page }: BookList.Props ): React.ReactElement => {
  const { years } = React.useContext<BookFilter.Props>( BookFilterContext )

  if ( page === 'previousKits' ) {
    books = books.slice( 0, 6 )
  }

  return (
    <>
      <S.Wrapper
        className="book-list__wrapper"
        color={ colors[ category ].DEFAULT! }
        page={ page }
      >
        { books.map( book => {
          const [ bookYear ]: string[] = book.book.book.tagMonth.split( '-' )
          const showBook = years.some( year => year.value === bookYear && year.active )
          const someFilterActive = years.some( year => year.active )

          return (
            ( !someFilterActive || showBook || page === 'previousKits' ) &&
            <Link
              className="book-list__book-button"
              key={ book.book.id }
              to={ PathNavigation.generateSlugPreviousKits( category, book?.book?.book?.title ) }
            >
              <BookPreview
                book={ book.book }
                category={ category }
                key={ book.book.id }
              />
            </Link>
          )
        } ) }
        { page === 'previousKits' && (
          <Button
            className="book-list__view-more-button"
            backgroundColor={ colors[ category ].OUTLINE!.BUTTON_BACKGROUND }
            border={ colors[ category ].OUTLINE!.BUTTON_COLOR }
            color={ colors[ category ].OUTLINE!.BUTTON_COLOR }
            hover={ colors[ category ].OUTLINE!.BUTTON_HOVER }
            href={ PathNavigation[ `${category}KitsPassados` ] }
          >
            { previousKitsTexts.VIEW_MORE } <Arrow />
          </Button>
        ) }
      </S.Wrapper>
      <BookButtons
        category={ category }
        page={ page }
      />
    </>
  )
}

namespace BookList {
  export interface Props {
    category: Category.Type
    books: Array<{ book: Book }>
    page: PageKits
  }
}

export default BookList
