import React from 'react'
import * as S from './styles'
import * as PathNavigation from '../../PathNavigation'
import { ReactComponent as Grid } from '../../../images/kitsPassados/grid.svg'
import curadoria from '../../../images/kitsPassados/logo-curadoria.svg'
import ineditos from '../../../images/kitsPassados/logo-ineditos.svg'
import { Typography } from '@taglivros/tag-components'
import { Category } from '@shared/interfaces/Category'
import { Link } from 'gatsby'

const BookListHeader = ( { category, description }: BookListHeader.Props ): React.ReactElement => {
  return (
    <S.Container>
      <S.Wrapper className="book-list-header__wrapper">
        <Link
          className={ `book-list-header__link--${category}` }
          to={ PathNavigation[ `${category}KitsPassados` ] }
        >
          <S.Logo
            className={ `book-list-header__logo--${category}` }
            src={ category === 'curadoria' ? curadoria : ineditos }
          />
        </Link>
        <Typography
          as="p"
          color="GREY-700-TXT"
          fontFamily="notoSans"
          fontWeight="s"
          variant="text2"
        >
          { description }
        </Typography>
        <Link
          className="book-list-header__grid"
          to={ PathNavigation[ `${category}KitsPassados` ] }
        >
          <Grid />
        </Link>
      </S.Wrapper>
    </S.Container>
  )
}

namespace BookListHeader {
  export interface Props {
    category: Category.Type
    description: string
  }
}

export default BookListHeader
